// SECTIONS
//------------

section {
	&.white-background{
		padding:50px 0px;
		background: $white;
	}
}

.intro {
	padding:0px 0px 50px 0px;
	background: $white;
}


.container-wide {
	width: 100%vw;
	&.right {
		width: 100%;
		padding-left: 0px;
		margin-left: auto;
		@media (min-width: $screen-desktop) {
			padding-left: 50px;
		}
		.content {
			padding-left: 15px;
		}
	}
}

