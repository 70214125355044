// ----------------------
// VARIABLES
// ----------------------


// COLORS:
// -------

$yellow:                #EBB220;

// White
// -------------------------
$white:					#ffffff;

// Black
// -------------------------
$black:					#262626;
$black-text:			#262626;

// Grays
// -------------------------
$gray:					#767676; //used on secondary text
$gray-light:			#d3d3d3; //used on hr and lines
$gray-lighter:			#f4f4f4; //used on tabs
$gray-lighter2:			#f5f5f5; //used on tabs
$gray-dark:             #707070; //used on hr


// Purple
// -------------------------
$purple:				#6a3956;


// Blues
// -------------------------

$blue:					#2b7cb4; //button blue
$blue-light:			#d4eaed; //contact footer blue
$blue-aqua:				#02afb4; //footer nav aqua
$blue-dark:				#02afb4; //footer nav aqua


// Greens
// -------------------------
$green:					#008567; //text green
$green-light:			#00ac85; //link arrow and underline


// Orange
// -------------------------
$orange:					#E68525;


$body-color:            $black-text !default;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-sans-serif:      'Open Sans', sans-serif;
// $font-family-base:            $font-family-sans-serif ;

$font-family-sans-serif:      'Oswald', sans-serif;
$font-family-base:            Arial, Helvetica, sans-serif;


// stylelint-enable value-keyword-case

$font-size-large:               1rem * 1.125 !default; // 18px
$font-size-base:              	1rem !default; // 16px
$font-size-md:             		1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:           		1rem * .75 !default; // 12px


$font-bold:            		600;
$font-reg:					400;

$h1-font-size:                $font-size-base * 2.5; // 60px
$h2-font-size:                $font-size-base * 2; // 36px
$h3-font-size:                $font-size-base * 1.5625; // 25px
$h4-font-size:				  $font-size-base * 1.2222; // 22px
$h5-font-size:                $font-size-base; // 18px
$h6-font-size:                $font-size-base * 1.5625; // 25px




$hr-border-color:             $gray-light !default;
// $hr-border-width:             $border-width !default;
// $hr-margin-y:                 $spacer !default;




// Media queries breakpoints
// --------------------------------------------------

// // Extra small screen / phone
// $screen-xss:              300px;


// Extra small screen / phone
$screen-xs:                  480px;
$screen-phone:               $screen-xs;

// Small screen / tablet
$screen-sm:                  768px;
$screen-tablet:              $screen-sm;

// Medium screen / desktop
$screen-md:                  992px;
$screen-desktop:             $screen-md;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-desktop:          $screen-lg;


