// HERO - Mastheads
//------------


.hero {
	margin-bottom: 0px;
	position: relative;
	display: block;
	height: 450px;
	display: flex;
	flex-direction: column;
	padding: 40px 0px 80px 0px;
	justify-content: flex-end;
	&.home {
		background:url(../img/hero/hero-home.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.res {
		background:url(../img/hero/hero-res.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.res-products {
		background:url(../img/hero/hero-res-products.jpg);
		background-size: cover;
		background-position: top left;
		background-repeat: no-repeat;
		align-items: start;
		@media (min-width: $screen-desktop) {
			align-items: end;
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
		}
	}
	&.res-estar {
		background:url(../img/hero/R-EStar-Hero-1366x470.jpg);
		background-size: cover;
		background-position: bottom right;
		background-repeat: no-repeat;
		padding: 40px 0px 100px 0px;
		@media (min-width: $screen-desktop) {
			padding: 40px 0px 80px 0px;
		}

	}
	&.res-improvement {
		background:url(../img/hero/R-Improvements-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.res-coolsaver {
		background:url(../img/hero/R-CoolSaver-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.res-multi {
		background:url(../img/hero/R-MultiFamily-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.com-home {
		background:url(../img/hero/Commercial-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.com-big {
		background:url(../img/hero/C-BigBuilding-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.com-incentives{
		background:url(../img/hero/C-MidstreamLighting-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.com-small{
		background:url(../img/hero/SmallBiz-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.com-load{
		background:url(../img/hero/C-LoadManagment-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	&.com-cool{
		background:url(../img/hero/C-CoolSaver-Hero-1366x470.jpg);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}




}



.hero-secondary {
	margin-bottom: 0px;
	position: relative;
	display: block;
	height: 450px;
	background:url(../img/hero/R-EfficeintProducts-Body-1366x470.jpg);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}


.ribbon-text {
	width: 80%;
	padding: 10px;
	background: rgb(232,179,0);
	background: linear-gradient(90deg, rgba(232,179,0,1) 27%, rgba(230,133,36,1) 100%);
	text-align: right;
	@media (min-width: $screen-desktop) {
		width: 60%;
	}
	h1 {
		text-transform: uppercase;
		color: $black;
		margin-bottom: 0px;
	}
}

.ribbon-text-right {
	width: 80%;
	padding: 10px;
	background: rgb(232,179,0);
	background: linear-gradient(90deg, rgba(232,179,0,1) 27%, rgba(230,133,36,1) 100%);
	@media (min-width: $screen-desktop) {
		width: 60%;

	}
	h1 {
		text-transform: uppercase;
		color: $black;
		margin-bottom: 0px;
	}
}


