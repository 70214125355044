// CARDS
//------------

.card-left {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 80px;
	@media (min-width: $screen-desktop) {
		flex-direction: row;
	}
	.image {
		border-bottom: 10px solid $orange;
		margin-bottom: 30px;
		width: 100%;
		@media (min-width: $screen-desktop) {
			margin-bottom: 0px;
			width: 50%;
			background-color: grey;
		}
		img {
			width: 100%;
		}
	}
	.content {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 50%;
		padding-right: 30px;
		padding-left: 30px;
		@media (min-width: $screen-desktop) {
			padding:50px;
			background-color: $gray-lighter2;
		}
		h1 {
			text-transform: uppercase;
			margin-bottom: 20px;
		}
	}
}

.card-right {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 80px;
	@media (min-width: $screen-desktop) {
		flex-direction: row;
	}
	.content {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 50%;
		padding-right: 30px;
		padding-left: 30px;
		order: 2;
		@media (min-width: $screen-desktop) {
			padding:50px;
			background-color: $gray-lighter2;
			order: 0;
		}
		h1 {
			text-transform: uppercase;
			margin-bottom: 20px;
		}
	}
	.image {
		border-bottom: 10px solid $orange;
		margin-bottom: 30px;
		width: 100%;
		@media (min-width: $screen-desktop) {
			margin-bottom: 0px;
			width: 50%;
			background-color: grey;
		}
		img {
			width: 100%;
		}
	}
}


.card-primary {
	background-color: $blue-light;
	.image {
		border-bottom: 10px solid $orange;
		width: 100%;
		img {
			width: 100%;
		}
	}
	.content {
		padding:30px 40px;
	}
}

.card-product-container {
	margin-bottom: 20px;
	width: 100%;
	@media (min-width: $screen-desktop) {
		width: 33.3%;
	}

	.card-product {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;

		.image {
			display: block;
			height: 200px;
			width: 200px;
			background-color: $blue-light;
			border-radius: 50%;
			display: inline-block;
			margin-bottom: 20px;
		}
		.text {
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
			button {
				margin-bottom:10px;
			}
		}
	}
}

.card-blue {
	padding:40px;
	background-color: $blue-light;
	border-bottom: 10px solid $orange;
}

.card-icon {
	text-align: center;
	margin-bottom: 40px;
	@media (min-width: $screen-desktop){
		margin-bottom: 0px;
	}
	.circle-number {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		display: block;
		height: 76px;
		width: 76px;
		background-color: $blue-aqua;
		border-radius: 50%;
		display: inline-block;
		margin-bottom: 20px;
		&.yellow {
			background-color: $yellow;
		}
		h1 {
			line-height: 2.5;
			@media (min-width: $screen-tablet) {
				line-height: 1.7;
			}

		}
	}
}
