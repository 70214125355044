// NAVIGATION

nav {
	width: 100%;
	background-color: white;
	z-index: 999;
	position: relative;
	font-family: $font-family-base;
	.heading {
		width: 100%;
		background-color: white;
		display: flex;
	    flex-wrap: wrap;
		justify-content: space-between;
		padding: 0px;
		flex-direction: column;
		// -webkit-box-shadow: 0px 4px 10px -1px rgba(0,0,0,0.41); 
		// box-shadow: 0px 4px 10px -1px rgba(0,0,0,0.41);
		border-bottom: 10px solid $orange;
		@media only screen and (min-width: 1182px) {
			flex-direction: row;
			align-items: center;
			padding: 20px 40px;
			flex-wrap: nowrap;
		}
		.logo-and-control {
			display: flex;
			padding: 20px 0px;
			position: relative;
			flex-direction: column;
			@media only screen and (min-width: 1182px) {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 0px 0px 0px 0px;
				border-bottom: none;
				flex-wrap: nowrap;
			}
			img {
				width: max-content;
				height: 100%;
				@media screen and (min-width: 1182px) {
					flex: none;
					height: auto;
				}
			}
			.toggle-nav {
				position: absolute;
				top:20;
				right:0;
				@media screen and (min-width: 1182px) {
					display: none;
				}
				&:after {
					content: "\f0c9";
					font-family: 'FontAwesome';
					font-size: 2rem;
					cursor: pointer;
					color: $black;
				}
				&.active {
					&:after {
						content: "\f00d";
						font-family: 'FontAwesome';
						font-size: 2rem;
						cursor: pointer;
						color: $black;
					}
				}
			}

			.nav-items {
				display:none;
				padding-top: 20px;
				@media screen and (min-width: 1182px) {
					display: flex !important;
				}
				ul {
					padding: 5px 20px 0px 20px;
					list-style-type: none;
					margin: 0px;
					width: 100%;
					@media screen and (min-width: 1182px) {
						flex: 1 1 100%;
						display: flex ;
						align-items: center;
						list-style: none;
						justify-content: flex-end;
						margin: 0px;
						padding: 0px;
					}

					li {
						padding:15px 0px;
						position: relative;
						@media screen and (min-width: 1182px) {
							padding: 0px 25px;
							&:last-child {
								padding-right: 0px;
							}
						}
						a {
							padding: 0px 0px 3px 0px;
							text-decoration: none;
							&:hover {
								border-bottom: 4px solid $orange;
								text-decoration: none;
								font-weight: 900;
							}
							&:active {
								border-bottom: 4px solid $orange;
								text-decoration: none;
								font-weight: 900;
							}
							&.active {
								border-bottom: 4px solid $orange;
								text-decoration: none;
								font-weight: 900;
								cursor: default;
							}
						}
					}
				}
				ul.mobile-only {
					padding:0px;
					@media screen and (min-width: 1182px) {
						display: none;
					}
				}

			}
		}
	}
}
