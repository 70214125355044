// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// $spacer: 1rem !default;
$spacer: 5px;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    // 1: ($spacer * .25),
    // 2: ($spacer * .5),
    // 3: $spacer,
    // 4: ($spacer * 1.5),
    // 5: ($spacer * 3)

    1: ($spacer * 1), //5px
    2: ($spacer * 2), //10px
    3: ($spacer * 3), //15px
    4: ($spacer * 4), //20px
    5: ($spacer * 5), //25px
    6: ($spacer * 6), //30px
    7: ($spacer * 7), //35px
    8: ($spacer * 8), //40px
    9: ($spacer * 9), //45px
    10: ($spacer * 10), //50px
    11: ($spacer * 11), //55px
    12: ($spacer * 12), //60px
    13: ($spacer * 13), //65px
    14: ($spacer * 14), //70px
    15: ($spacer * 15), //75px
    16: ($spacer * 16), //80px
    17: ($spacer * 17), //85px
    18: ($spacer * 18), //90px
    19: ($spacer * 19), //95px
    20: ($spacer * 20) //100px
  ),
  $spacers
);






