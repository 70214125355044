.icon-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding:0px 60px 0px 60px;
    @media (min-width: $screen-desktop) {
        flex-direction: row;

    }
}

.icon-item {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 0px 20px 20px 20px;
    width: 100%;
    @media (min-width: $screen-desktop) {
        margin-bottom: 0px;
    }
    img {
        margin-bottom: 10px;
    }

}



