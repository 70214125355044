// BUTTONS
//------------


.btn-primary {
	font-family: $font-family-base;
    background-color: $black;
    display: flex;
    color: $white;
    border: none;
    border-radius: 4px;
    padding: 10px 30px;
	outline: 0;
	font-size: $font-size-large;
	transition: .03s;
	letter-spacing: 1px;

	&:hover {
		color: $black;
		background-color: $orange;
	}
	&:active {
		background-color: $orange;
		outline: 0;
	}
	&:focus,
 	&.focus {
		outline: 0;
	}
	&:active:focus {
		color: $black !important; 
		background-color: $orange !important; 
		border-color: blue;
	}
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	-moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}




.btn-secondary {
	font-family: $font-family-sans-serif;
    background-color: $white;
    display: flex;
    color: $black;
    border: 1px solid $black;
    border-radius: 4px;
    padding: 5px 30px;
	outline: 0;
	font-size: $font-size-large;
	transition: .03s;
	letter-spacing: 1px;
	@media (min-width: $screen-desktop) {
		font-family: $font-family-base;
	}
	&:hover {
		color: $white;
		border-color: $black;
		background-color: $black;
	}
	&:active {
		background-color: $black;
		outline: 0;
	}
	&:focus,
 	&.focus {
		outline: 0;
	}
	&:active:focus {
		color: $white !important; 
		background-color: $blue-light !important; 
		border-color: blue;
	}
}


.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-dark.dropdown-toggle:focus {
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	-moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}




// LINKS
//------------


a {
	color: $black;
	text-decoration: underline;
	&:hover {
    border-radius: 0;
	color: $black;
	}
	&.invert {
		color: $white;
	    border-bottom: 2px solid $white;
	    border-radius: 0;
	    text-decoration: none;
		&:hover {
		    border-bottom: 2px solid $white;
		    border-radius: 0;
		    text-decoration: none;
		}
	}
}


