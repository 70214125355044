// TYPE
//------------
body {
	font-family: $font-family-base;
	font-size: $font-size-md;
	@media (min-width: $screen-tablet) {
		font-size: $font-size-base;
	}
}


p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: $font-size-md;
	@media (min-width: $screen-tablet) {
		font-size: $font-size-md;
	}
	@media (min-width: $screen-desktop) {
		font-size: $font-size-base;
	}
	small {
		font-size:$font-size-sm;
		font-weight: inherit;
	}
	em {
		span {
			color: $purple;
		}
	}
}

h3 {
	font-family: $font-family-base;
	font-weight: $font-bold;
}

h6 {
	font-family: $font-family-base;
	font-weight: $font-reg;
	font-size: $h6-font-size;
}


h4, h5{
	font-weight: $font-reg;
	margin-bottom: 0px;
}

h1, h2, h4, h5 {
	letter-spacing: 2px;
	font-family: $font-family-sans-serif;
	color: $black;
}


h1 {
	color: inherit;
	font-size: 30px;
	font-weight: $font-reg;
	@media (min-width: $screen-tablet) {
		font-size: $h1-font-size;
	}
}

h2 {
	color: inherit;
	font-size: $h2-font-size;
	font-weight: $font-reg;
	line-height: 1.3;
	margin-bottom: 0px;
}


h3 {
	color: inherit;
	font-size: 22px;
	@media (min-width: $screen-tablet) {
		font-size: $h3-font-size;
	}
}


h4 {
	font-size: $h4-font-size;
	font-weight: $font-reg;
}



h5 {
	font-size: $h5-font-size;
}




// h6 {
// 	color:$black;
// 	font-size: 24px;
// 	@media (min-width: $screen-tablet) {
// 		font-size: $h6-font-size;
// 	}
// }

hr {
	border-color: $gray-dark;
}

.text-center {
	text-align: center;
}