@mixin make-container($gutter: $grid-gutter-width) {
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
    margin-right: auto;
    margin-left: auto;
  }
  

$container-large-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px
) !default;

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-large-max-widths($max-widths: $container-large-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-large-max-width in $max-widths {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        max-width: $container-large-max-width;
      }
    }
  }

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
  margin-right: auto;
  margin-left: auto;
}

.container-large {
    @include make-container();
    @include make-container-large-max-widths();
  }