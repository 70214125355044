// COLORS
//------------

.gray {
	color: $gray !important;
}

.green {
	color: $green !important;
}

.black {
	color: $black-text !important;
}

.white {
	color: $white !important;
}