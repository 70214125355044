
// FOOTER

footer {
	.talk {
		padding:30px 0px;
		background-color: $blue-light;
		display: flex;
		flex-direction:column;
		@media (min-width: $screen-desktop) {
			flex-direction: row;
			align-items: center;
		}
		.text {
			display: flex;
			flex-direction:column;
			@media (min-width: $screen-desktop) {
				flex-direction: row;
				align-items: center;
			}
			h3 {
				text-transform: uppercase;
				margin-bottom: 5px;
				@media (min-width: $screen-desktop) {
					flex: 0 1 30%;
					margin-bottom: 0px;
				}
			}
			p {
				margin-bottom: 0px;
				flex-grow: 0;
			}
		}
		.cta {
			display: flex;
			margin-top: 20px;
			@media (min-width: $screen-desktop) {
				margin-top: 0px;
				justify-content: flex-end;
			}
			button {
				&:last-child {
					margin-left:20px;
				}
			}	
		}
	}

	.footer-nav {
		padding:30px 0px;
		background-color: $blue-aqua;
		font-family: $font-family-sans-serif;
		.footer-nav-items {
			display: flex;
			ul {
				padding: 5px 20px 0px 20px;
				margin: 0px;
				width: 100%;
				list-style: none;
				@media screen and (min-width: 1182px) {
					flex: 1 1 100%;
					display: flex ;
					align-items: center;
					justify-content: center;
					margin: 0px;
					padding: 0px;
				}

				li {
					padding:15px 0px 0px 0px;
					position: relative;
					text-align: center;
					position: relative;
					::after {
						content: "";
						display: block;
						padding-top: 20px;
						border-bottom: 1px solid black;
						width: 40px;
						margin: 0px auto;
						text-align: center;
						@media screen and (min-width: 1182px) {
							border-bottom: none;
							padding:0px
						}
					}
					&:last-child {
						::after {
							content: "";
							display: block;
							padding-top: 20px;
							border-bottom: none;
							width: 40px;
							margin: 0px auto;
							text-align: center;
							@media screen and (min-width: 1182px) {
								border-bottom: none;
								padding:0px
							}
						}
					}
					@media screen and (min-width: 1182px) {
						padding: 0px 25px;
						text-align: left;
						&:last-child {
							padding-right: 0px;
						}
					}
					a {
						padding: 0px;
						font-size: $h4-font-size;
						font-family: $font-family-base;
						text-decoration: none;

						&.active {
							border-bottom: 4px solid white;
							font-weight: $font-bold;
						}
						&:hover {
							border-bottom: 4px solid white;
							font-weight: $font-bold;
						}
					}
				}
			}
		}
	}

	.footer-base {
		padding:30px 0px 80px 0px;
		background-color: white;
		.contact-us {
			border-bottom: 1px solid $black;
			padding-bottom:20px;
			align-items: center;
			.text {
				display: flex;
				flex-direction:column;
				margin-bottom: 20px;
				text-align: center;
				@media (min-width: $screen-desktop) {
					text-align: left;
					flex-direction: row;
					align-items: center;
					margin-bottom:0px;
				}
				h5 {
					text-transform: uppercase;
					margin-bottom: 20px;
					@media (min-width: $screen-desktop) {
						margin-bottom: 0px;
					}
				}
				ul {
					padding-left: 0px;
					margin-bottom: 0px;
					flex-grow: 0;
					width: 100%;
					list-style: none;
					@media (min-width: $screen-desktop) {
						padding-left: 20px;
					}
				}
			}
			.cta {
				margin-bottom: 30px;
				display: block;
				text-align: center;
				button {
					display: inline-block;
				}
				@media (min-width: $screen-desktop) {
					display: flex;
					border-right: 1px solid $black;
					border-left: 1px solid $black;
					justify-content: center;
					margin-bottom: 0px;
				}
			}
			.social {
				display: block;
				text-align: center;
				font-size: $h2-font-size;
				margin-bottom: 0px;
				@media (min-width: $screen-desktop) {
					justify-content: end;
				}
				a {
					margin: 0px 10px;
					&:hover {
						border-bottom: 0px;
						border-radius: 0;
						text-decoration: none;
					}
				}
			}
		}
		.footer-logos {
			padding: 40px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (min-width: $screen-desktop) {

				justify-content: space-between;
			}
			img {
				&:first-child {
					margin-bottom: 20px;
				}
				@media (min-width: $screen-desktop) {

				}
			}
		}
		.footer-tc {
			margin-top: 20px;;
			text-align: center;
		}
	}
}




