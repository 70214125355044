// LISTS
//------------

ul.unstyled {
	list-style: none;
	padding-left: 0px;
}


ul.inline-pipe-small {
	list-style: none;
	padding-left: 0px;
	li {
		display: inline;
		padding: 0 10px;
		border-left: solid 1px black;
		font-size:$font-size-sm;
		&:first-child {
			border-left: none;
		}
	}
}

ul.default {
	li {
		margin-bottom: 20px;
	}
}
